<template>
  <div class="w-full" >
    <div class="flex  pt-20">
      <banner-component :is-display-search-inputs="isDisplaySearchInputs"></banner-component>
    </div>
    <div class="mx-auto py-3 px-6 lg:px-8" v-if="provider">
      <breadcrumb-component :pages="pages"></breadcrumb-component>
    </div>
    <div class="flex flex-col lg:flex-row lg:container mx-auto px-6 lg:px-8" v-if="!isLoading">
      <div class="flex flex-col w-full lg:w-2/3">
        <div class="flex w-full" v-if="provider.provider && medias">
          <portfolio-component
              :provider="provider.provider"
              :medias="medias"
              :photos="photos"
              :videos="videos"
              :display-modal-props="displayModal"
              @quote-request="sendQuoteRequest"
              @portfolio-modal-opened="togglePortfolioModal"
          ></portfolio-component>
        </div>
        <br />
        <div class="flex py-4" v-if="provider.provider" >
          <description-component :provider="provider" ></description-component>
        </div>
        <br />
        <div class="flex py-4" v-if="provider.provider">
          Ce prestataire  est aussi dans &nbsp;<a  v-for="(service,index) in provider.provider.service_type" :key="index" :href="'/prestataires-de-mariage-details/'+service.pivot.id" class="text-xs font-semibold mr-2 px-4 py-0.5 rounded border inline-flex items-center justify-center">{{service.name}}</a>
        </div>
        <div class="flex" v-if="addresses && addresses.data">
          <address-list-component :addresses="addresses.data"></address-list-component>
        </div>
        <br />
        <div class="flex"  v-if="provider.provider" >
          <review-list-component @review-comment-item-deleted="onCommentDelete" @review-comment-list-paginate="onChangePage" :provider="provider" :provider_name="provider.provider.name" :comments="comments"></review-list-component>
        </div>
        <br />
        <div class="flex py-2 px-2 w-full pb-4" id="noterprestataire">
          <comment-form-component @comment-form-submitted="onCommentSubmit" :provider="provider"></comment-form-component>
        </div>
        <br>
        <br>
        <br>
        <br>
      </div>
      <div class="w-full flex flex-col lg:flex-row lg:w-1/3 lg:pl-8" ref="contact-form-component" v-if="provider.provider">
        <contact-form-component
            :user="user"
            :provider="provider.provider"
            :currentSubscription="provider.subscriptions[0]"
            @quote-request-form="sendQuoteRequest"
        ></contact-form-component>
      </div>
    </div>
    <div class="flex container mx-auto px-8 text-center w-full" v-else>
      Chargement en cours ...
    </div>
    <div v-if="provider" class="fixed bottom-0 w-full py-0.5 flex bg-white z-10 lg:hidden">
      <banner-bottom-mobile
          :provider="provider.provider"
          @scroll-to="scrollTo"
      ></banner-bottom-mobile>
    </div>
  </div>
</template>

<script>
import BannerBottomMobile from "@/views/wedding-provider/components/banner-bottom-mobile";
import ContactFormComponent from "@/views/wedding-provider/components/contact-form";
import DescriptionComponent from "@/views/wedding-provider/components/description-component";
import { QUOTE_REQUEST_TYPE } from "@/views/wedding-provider/utils";
import { mapActions, mapState } from "vuex";
// import store from '@/store';
import { useMeta } from 'vue-meta';
export default {
  name: "ProviderDetailPage",
  setup () {
    useMeta({
      title:
        "Jours de Joie - Prestataire de services - Mariage a Abidjan-devis de mariage-prestataire de mariage",
      titleTemplate: "",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "keywords",
          content:
            "Agence d’organisation de mariage en Côte d’Ivoire, wedding planner, choix de prestation, prestataire de mariage,mariage a abidjan, event planner , prestation de mariage , prestataire de mariage, robe de mariage ",
        },
        {
          name: "description",
          content: "Agence d’organisation de mariage en Côte d’Ivoire, Organisation de Mariage à Abidjan, wedding planner en cote d'ivoire , wedding planner a abidjan,alliance de mariage",
        },
      ],
    })
  },
  components: {
    BannerBottomMobile,
    ContactFormComponent,
    DescriptionComponent
  },
  data: () => ({
    displayModal: false,
    requestTypes: QUOTE_REQUEST_TYPE,
    mark: {
      global: 0,
      professionalism: 0,
      quality_price_ratio: 0,
      recommendation: 0,
    },
  }),
  computed: {
    // ...mapState(['user']),
    ...mapState('providers', { provider: 'provider' }),
    ...mapState('medias', { medias: 'medias' }),
    ...mapState('medias', { photos: 'photos' }),
    ...mapState('medias', { videos: 'videos' }),
    ...mapState('addresses', { addresses: 'addresses' }),
    ...mapState('comments', { comments: 'comments' }),
    user() {
      // return false;
      return JSON.parse(this.$store.state.user);
    },
    isLoading() {
      // return false;
      return this.$store.state.storeWedding.providers.isLoading && this.provider && this.medias;
    },
    pages() {
      return [
        { path: '/annuaires-des-prestataires-de-services-mariage-en-cote-divoire', label: 'LISTE DES PRESTATAIRES' },
        { path: '#', label: '	➞' },
        { path: '#', label: this.provider.provider && this.provider.provider.name }
      ];
    }
  },
  methods: {
    ...mapActions('auth', ['showMe']),
    ...mapActions('providers', ['getProvider']),
    ...mapActions('medias', ['getMedias']),
    ...mapActions('addresses', ['getAddresses']),
    ...mapActions('comments', ['getComments', 'storeComment', 'deleteComment']),
    ...mapActions('quoteRequests', ['storeQuoteRequest']),
    async onChangePage(page) {
      const filters = {
        page: page,
        service_type_id: this.$route.query.service_type_id,
        town_id: this.$route.query.town_id,
      }
      await this.getComments(filters);
    },
    async onCommentSubmit(comment) {
      
      await this.storeComment(comment);
      window.location = '/prestataires-de-mariage-details/' + this.$route.params.id;
    },
    async onCommentDelete(commentId) {
        await this.deleteComment(commentId);
        window.location = '/prestataires-de-mariage-details/' + this.$route.params.id;
    },
    async sendQuoteRequest(intent) {
      
      const quoteRequest = {...intent, wedding_provider_id: this.provider.wedding_provider_id, shop_id: this.$route.params.id, service: this.provider.service_type.name}
      
      let number = this.provider.provider.phone_number_1.replace(/[^a-zA-Z0-9]/g,'');
      
      await this.storeQuoteRequest(quoteRequest);
      if(intent.type === this.requestTypes.PHONE) {
          window.open('tel:'+this.provider.provider.phone_number_1, '_blank');
      }
      if(intent.type === this.requestTypes.WHATSAPP) {
        // window.open(this.provider.website;
          window.open('https://api.whatsapp.com/send?phone='+number, '_blank')
      }
      if(intent.type === this.requestTypes.WEBSITE) {
        window.open(this.provider.provider.website, '_blank');
      }
    },
    async togglePortfolioModal() {
      this.displayModal = !this.displayModal
    },
    scrollTo(refName) {
      this.scrollMeTo(refName)
    },
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop - 200;
      window.scrollTo(0, top);
    }
  },
  beforeMount:function(){
    if(this.$store.getters.isLoggedIn==false){
        this.$router.push('/connexion');
    }
  },
  async mounted() {
    const { id } = this.$route.params;
    await this.getProvider({ id });
    this.$ua.trackEvent("Provider", "Click", this.provider.provider.name, null);
    await this.getMedias({ provider_id: id });
    await this.getAddresses({ provider_id: this.provider.wedding_provider_id });
    await this.getComments({ wedding_provider_id: id });
    if (this.token && !this.user) {
      await this.showMe()
    }
    
  },
}
</script>
