<template>
  <div class="bg-container-wedding-card">
    <div>
      <Bar />  
   
      <div class="container p-3" style="padding: 25px;">
        <big-title label="JOY CARDS" />
        <br />
        <!-- <p class="text-center">
          Plus besoin de vous déplacer pour distribuer les cartons d’invitations
          et de remerciement.
          <br />Mettez-en plein la vue à vos convives en économisant votre temps
          ou votre argent grâce aux Joy Cards, les faire-parts numériques.
        </p> -->

        <p class="text-center">
          Parce que les invitations nuptiales à savoir les faire parts de<br />
          mariage font partie des actions les plus importantes dans <br />
          l’organisation du mariage, il est nécessaire de bien s’y prendre.<br />
          La rubrique Joy Cards vient répondre à bien niveau à tous vos besoins.
        </p>

        <div class="text-center">
          <p class="collapse" id="collapseJC">
            Plus besoin de vous déplacer pour distribuer les cartons
            d’invitations, <br />
            plus besoin de vous stresser en rappelant le programme de la
            cérémonie à vos invités, <br />
            plus besoin de se demander qui à confirmer. <br /><br />
            Avec Joy Cards vous aurez plus de tranquillité pour la distribution
            de vos faires parts et <br />
            même après le mariage vous pouvez l’utiliser pour envoyer des
            messages de remerciements à vos invités.<br />
            Mettez-en plein la vue à vos convives en économisant votre temps ou
            votre argent grâce aux Joy Cards, <br />
            les faire-part numériques qui facilite la vie !
          </p>
          <a
            class="btn btn-link btn-sm"
            style="color: #b71c1c; text-decoration: none;"
            data-toggle="collapse"
            href="#collapseJC"
            role="button"
            aria-expanded="false"
            aria-controls="collapseJC"
            @click="textFullDisplay = !textFullDisplay"
          >
            <strong>{{
              textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
            }}</strong>
          </a>
        </div>
        <br />
        <div v-if="isLoading == true">
          <spinner  /><br /><br />
        </div>
        <div
          class
          v-else-if="user.email_verified_at != null && customer.dob != null"
        >
          <div class="faire-parts-container p-3">
            <div class="row" v-if="guestsCount != null && guestsCount < 10">
              <div class="col-md-12">
                <div class="alert alert-warning text-center">
                  <p>
                    Vous devez avoir au moins 
                    <router-link
                      to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
                      style="color:red">10 invités</router-link
                    > pour pouvoir envoyer une Joy Card !
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" v-if="summary == null">
                <div class="alert alert-warning text-center">
                  <p>
                    Pensez à correctement renseigner les informations de la
                    rubrique
                    <router-link
                      to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref"
                      style="color:red">en bref</router-link
                    >.
                  </p>
                </div>
              </div>
              <div class="col-md-12" v-else-if="summary != null">
                <div
                  class="alert alert-warning text-center"
                  v-if="summary == 'Moins de 100' && guestsCount > 100"
                >
                  <p>
                    Juste un peu d'effort pour renseigner la liste de vos
                    invités. Vous avez initialement prévu {{ summary }} invités
                    et vous n'avez saisi que {{ guestsCount }} invités.
                  </p>
                </div>
                <div
                  class="alert alert-warning text-center"
                  v-else-if="
                    summary == '100 - 200' &&
                      (guestsCount < 100 || guestsCount > 200)
                  "
                >
                  <p>
                    Juste un peu d'effort pour renseigner la liste de vos
                    invités. Vous avez initialement prévu {{ summary }} invités
                    et vous n'avez saisi que {{ guestsCount }} invités.
                  </p>
                </div>
                <div
                  class="alert alert-warning text-center"
                  v-else-if="
                    summary == '200 - 300' &&
                      (guestsCount < 200 || guestsCount > 300)
                  "
                >
                  <p>
                    Juste un peu d'effort pour renseigner la liste de vos
                    invités. Vous avez initialement prévu {{ summary }} invités
                    et vous n'avez saisi que {{ guestsCount }} invités.
                  </p>
                </div>
                <div
                  class="alert alert-warning text-center"
                  v-else-if="
                    summary == '300 - 400' &&
                      (guestsCount < 300 || guestsCount > 400)
                  "
                >
                  <p>
                    Juste un peu d'effort pour renseigner la liste de vos
                    invités. Vous avez initialement prévu {{ summary }} invités
                    et vous n'avez saisi que {{ guestsCount }} invités.
                  </p>
                </div>
                <div
                  class="alert alert-warning text-center"
                  v-else-if="
                    summary == '400 - 500' &&
                      (guestsCount < 400 || guestsCount > 500)
                  "
                >
                  <p>
                    Juste un peu d'effort pour renseigner la liste de vos
                    invités. Vous avez initialement prévu {{ summary }} invités
                    et vous n'avez saisi que {{ guestsCount }} invités.
                  </p>
                </div>
                <div
                  class="alert alert-warning text-center"
                  v-else-if="summary == 'Supérieur à 500' && guestsCount < 500"
                >
                  <p>
                    Juste un peu d'effort pour renseigner la liste de vos
                    invités. Vous avez initialement prévu {{ summary }} invités
                    et vous n'avez saisi que {{ guestsCount }} invités.
                  </p>
                </div>
              </div>
            </div>
            <div class="row" v-if="rc != null">
              <div class="col-md-12">
                <div
                  class="alert alert-danger text-center"
                  v-if="rc.phone_number_1 == null && rc.given_names == null"
                >
                  <p>
                    Renseignez correctement les informations de votre PCO dans
                    la rubrique
                    <router-link
                      to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/comite-restreint"
                      >« Comité Restreint »</router-link
                    >du Carnet Nuptial !
                  </p>
                </div>
              </div>
            </div>
            <div class="row" v-if="marriedMan != null">
              <div class="col-md-12">
                <div
                  class="alert alert-danger text-center"
                  v-if="
                    marriedMan.last_name == null &&
                      marriedMan.given_names == null
                  "
                >
                  <p>
                    Renseignez correctement les informations du Fiancé dans la
                    rubrique
                    <router-link
                      to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances"
                      >« Fiancés »</router-link
                    >du Carnet Nuptial !
                  </p>
                </div>
              </div>
            </div>
            <div class="row" v-if="marriedWoman != null">
              <div class="col-md-12">
                <div
                  class="alert alert-danger text-center"
                  v-if="
                    marriedWoman.last_name == null &&
                      marriedWoman.given_names == null
                  "
                >
                  <p>
                    Renseignez correctement les informations de la Fiancée dans
                    la rubrique
                    <router-link
                      to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances"
                      >« Fiancés »</router-link
                    >du Carnet Nuptial !
                  </p>
                </div>
              </div>
            </div>
            <div class="row pt-3 pb-3">
              <div class="col-md-12" v-if="guestsCount != null">
                <!-- <router-link
                  v-if="
                    guestsCount >= 5
                  "
                  class="primary-button float-right"
                  style="color:#fff;"
                  to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/nouvelle-carte-d-invitation-mariage"
                >
                  <i class="fa fa-comments-o" style="color:#fff;"></i
                  >&nbsp;Nouveau message
                </router-link> -->
                <router-link
                  class="primary-button float-right"
                  style="color:#fff;"
                  to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/nouvelle-carte-d-invitation-mariage"
                >
                  <i class="fa fa-comments-o" style="color:#fff;"></i
                  >&nbsp;Nouveau message
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div
                  v-if=" rows.length > 0 && isLoading == false"
                  class="table-responsive"
                  style="padding:5px;"
                >
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table
                        class="table table-bordered table-hover"
                        v-if="channels != null"
                      >
                        <thead>
                          <th
                            class="text-center"
                            v-for="(channel, ckey) in channels"
                            :key="ckey"
                          >
                            {{ channel.name }}
                          </th>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              class="text-center"
                              v-for="(channel, ckey) in channels"
                              :key="ckey"
                            >
                              {{ channel.pivot.remaining_messages }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else>
                        <spinner  />
                      </div>
                    </div>
                  </div>
                  <DataTable  class="display" order="desc" :options="options" width="100%">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Expediteur</th>
                            <th>Canal</th>
                            <th>Cible</th>
                            <th>Sujet</th>
                            <th>Succès</th>
                            <th>Echec</th>
                            <th>Total</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(guest,index) in rows" :key="index">
                        <td>{{ guest.created_at }}</td>
                        <td >{{ guest.from }}</td>
                        <td >{{ guest.channel.name }}</td>
                        <td>{{ guest.target }}</td>
                        <td>{{ guest.subject }}</td>
                        <td><span class="badge badge-success">{{ guest.success }}</span></td>
                        <td ><span class="badge badge-danger">{{ guest.error }}</span></td>
                        <td>{{ guest.total }}</td>
                        <td v-html="guest.actions">
                        </td>
                      </tr>
                    </tbody>
                  </DataTable>
                </div>
                <div v-else>
                  <spinner  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <saved-modal :user="user" @resendMailAccountVerification="resendMailAccountVerification" v-show="showModal" @close-modal="showModal = false" />
        <br />
        <center>
          <button class="default-button" @click="goToPrevious">
            PRÉCEDENT
          </button>
        </center>
      </div>
    </div>
  </div>
</template>

<style scoped>
.faire-parts-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  background: #fff;
}

.bg-container-wedding-card {
  width: 100%;
  position: relative;
}

.bg-container-wedding-card .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-wedding-card::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/wedding-cards.png");
  background-size: cover;
  opacity: 0.1;
}
</style>
<script setup>
import DataTablesCore from 'datatables.net';
import DataTable from 'datatables.net-vue3';

DataTable.use(DataTablesCore);

const data = [
  [1, 2],
  [3, 4],
];
</script>
<script>
import Bar from "@/components/nuptial-notebook/Bar.vue";
import {
API_PREFIX,
API_VERSION,
AUTH,
COMMUNICATION,
NUPTIAL_NOTEBOOK,
} from "@/config";
import axios from "axios";
import { mdbContainer, mdbDatatable } from "mdbvue";
import moment from "moment";
import { useMeta } from 'vue-meta';
export default {
  name: "nuptialNotebookCommunicationIndex",
  setup () {
    useMeta({
      title: "Joy Cards, Faire-Parts Numériques de Mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Commander des faire-parts en Côte d’Ivoire",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, commander des faire-parts en Côte d’Ivoire, prestataire de mariage, comment choisir le design de ses faire-parts, envoyer ses faire-parts par mail, faire-parts digital, invitation de mariage",
        },
      ],
    })
  },
  components: {
    Bar,
    mdbDatatable,
    mdbContainer,
  },
  data: function() {
    return {
      showModal:false,
      messages: [],
      columns: [],
      rows: [],
      isLoading: false,
      channels: null,
      purchases: [],
      guests: null,
      customer: {dob:null},
      user: JSON.parse(this.$store.state.user),
      summary: null,
      guestsCount: null,
      rc: null,
      marriedMan: null,
      marriedWoman: null,
      textFullDisplay: false,
      options:{
          "order": [[ 0, 'desc' ], [ 1, 'asc' ]]
      } 
    };
  },
  computed: {
    data() {
      return {
        columns: this.columns,
        rows: this.rows,
      };
    },
  },
  mounted: function() {
    this.getCustomerInfo()
      .then((customer) => {
        this.getMessages();
        this.getPurchases();
        this.getChannels();
        this.getGuestsCount(customer.event.id);
        this.getSummaries();
        this.getRC(customer.event.id);
        this.getMarriedMan(customer.event.id);
        this.getMarriedWoman(customer.event.id);
      })
      .catch((error) => {
        console.log("Mounted error", error);
      });

    this.show();
  },

  methods: {
    getCustomerInfo() {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.DEV_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.customer = response.data;
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            console.log("Error", error);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    momentRefactoring(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    goToPrevious() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
      );
    },
    getMessages() {
      let app = this;
      app.isLoading = true;
      axios
        .get(COMMUNICATION.DEV_URL + API_PREFIX + API_VERSION + "messages", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(function(response) {
          app.columns = [
            {
              label: "Expéditeur",
              field: "from",
              sort: "desc",
            },
            {
              label: "Canal",
              field: "channel",
              sort: "desc",
              format: (value) => value.name,
            },
            {
              label: "Cible",
              field: "target",
              sort: "desc",
              format: (value) => {
                if (value == "" || value == null || value == "-") {
                  return "<span>Aucune</span>";
                } else {
                  return "<span>" + value + "</span>";
                }
              },
            },
            {
              label: "Sujet",
              field: "subject",
              sort: "desc",
              format: (value) => {
                if (value == "" || value == null || value == "-") {
                  return "<span>Aucun</span>";
                } else {
                  return "<span>" + value + "</span>";
                }
              },
            },
            {
              label: "Succès",
              field: "success",
              sort: "desc",
              format: (value) => {
                if (value == "" || value == null || value == "-") {
                  return '<span class="badge badge-success">0</span>';
                } else {
                  return (
                    '<span class="badge badge-success">' + value + "</span>"
                  );
                }
              },
            },
            {
              label: "Echec",
              field: "error",
              sort: "desc",
              format: (value) => {
                if (value == "" || value == null || value == "-") {
                  return '<span class="badge badge-danger">0</span>';
                } else {
                  return (
                    '<span class="badge badge-danger">' + value + "</span>"
                  );
                }
              },
            },
            {
              label: "Total",
              field: "total",
              sort: "desc",
              format: (value) => {
                if (value == "" || value == null || value == "-") {
                  return '<span class="badge badge-dark">0</span>';
                } else {
                  return '<span class="badge badge-dark">' + value + "</span>";
                }
              },
            },
            {
              label: "Date",
              field: "created_at",
              sort: "desc",
              format: (value) => moment(value).format("DD/MM/YYYY à HH:mm"),
            },
            {
              label: "Action",
              field: "actions",
              sort: "desc",
            },
          ];
          response.data.map((message, key) => {
            let target = "";
            message.actions =
              '<a href="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage/' +
              message.id +
              '" class="btn btn-link btn-sm" data-toggle="tooltip" data-placement="top" title="Modifier"><i class="fa fa-edit"></i></a>';
            message.guests.map((guest, key) => {
              target =
                key + 1 < message.guests ? guest.name + " ," : guest.name;
            });
            let messageObj = message;
            messageObj.target = target;
            app.rows.push(messageObj);
            
          });
          app.isLoading = false;
        })
        .catch(function(error) {
          // console.log(error);
          app.isLoading = false;
        });
    },
    getSummaries() {
      this.isLoading = true;
      let data = {
        label: "Nombre d'invités prévus",
      };
      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "summaries-by-label",
          data,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.summary = response.data.pivot.response;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    getChannels() {
      let self = this;
      self.isLoading = true;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "customer-channels",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(function(response) {
          self.channels = response.data;
          
          self.isLoading = false;
        })
        .catch(function(error) {
          console.log(error);
          self.isLoading = false;
        });
    },
    getPurchases() {
      let self = this;
      self.isLoading = true;
      axios
        .get(
          COMMUNICATION.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "customer-purchases",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(function(response) {
          self.purchases = response.data;
          // console.log(self.purchases);
          self.isLoading = false;
        })
        .catch(function(error) {
          console.log(error.response.data);
          self.isLoading = false;
        });
    },
    goToMessage(messageId) {
      this.$router.push("/nuptial-notebook/communication/" + messageId);
    },
    getGuestsCount(eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "guests-by-event-get/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.guestsCount = response.data.length;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error.response.data);
        });
    },
    getRC(eventId) {
      this.isLoading = true;
      let data = {
        event_id: eventId,
        restricted_committee_name: "Informations du PCO",
      };
      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "restricted-committees-by-name",
          data,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          
          this.rc = response.data.pivot;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    getMarriedMan(eventId) {
      this.isLoading = true;
      let data = {
        event_id: eventId,
        married_name: "Informations du fiancé",
      };
      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "marrieds-by-name",
          data,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.marriedMan = response.data.pivot;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    getMarriedWoman(eventId) {
      this.isLoading = true;
      let data = {
        event_id: eventId,
        married_name: "Informations de la fiancée",
      };
      axios
        .post(
          NUPTIAL_NOTEBOOK.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "marrieds-by-name",
          data,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.marriedWoman = response.data.pivot;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    resendMailAccountVerification(customerEmail) {
      this.isLoading = true;
      axios
        .get(
          AUTH.DEV_URL +
            API_PREFIX +
            API_VERSION +
            "send-email-verification/" +
            customerEmail,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          
          this.$createToast(
          {
          title: 'Attention !',
          description: "Mail envoyé avec succès ! !"
          },
          {
          position: 'top-center',
          type: 'success',
          timeout: 10000,
          transition: 'bounce',
          })
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.$createToast(
          {
          title: 'Attention !',
          description: "Erreur lors de l'envoie de mail'!"
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
          this.isLoading = false;
        });
    },
    show() {
      if (!this.user.email_verified_at) {
        this.showModal= true
      }
    },
  },
};
</script>
